html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 300;
  overflow: hidden;
  font-family: sans-serif;
}

body,
#root {
  height: 100%;
}

.UnderConstruction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.UnderConstruction-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.UnderConstruction-container {
  margin-top: -50px;
  width: 100%;
  max-width: 800px;
  position: relative;
}
@media (max-width: 700px) {
  .UnderConstruction-container {
    margin-top: -120px;
  }
}

.UnderConstruction-logo-container {
  text-align: center;
}

.UnderConstruction-logo {
  width: 130px;
}

.UnderConstruction-title {
  margin: 0 auto;
  margin-top: 18px;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 700px) {
  .UnderConstruction-title {
    font-size: 30px;
  }
}

.UnderConstruction-description {
  margin: 0 auto;
  margin-top: 18px;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
}
@media (max-width: 700px) {
  .UnderConstruction-description {
    font-size: 16px;
  }
}

.UnderConstruction-notify {
  margin-top: 18px;
  text-align: center;
}
@media (max-width: 700px) {
  .UnderConstruction-notify {
    max-width: 100%;
    padding: 0 15px;
  }
}

.UnderConstruction-input {
  border-radius: 4px;
  width: 230px;
  height: 38px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border: 1px solid #fff;
  border-color: rgba(255, 255, 255, 0.3);
  padding-left: 15px;
  font-size: 14px;
  transition: all .2s ease-in;
  box-sizing: border-box;
}
.UnderConstruction-input::placeholder {
  color: #fff;
  font-size: 14px;
}
@media (max-width: 700px) {
  .UnderConstruction-input {
    padding-left: 10px;
    width: 100%;
  }
}
.UnderConstruction-input:focus {
  border-color: #fff;
  outline: none;
}

.UnderConstruction-notify-button {
  width: 100px;
  height: 40px;
  background-color: #ff3b30;
  border: 0;
  color: white;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all .2s ease-in;
}
@media (max-width: 700px) {
  .UnderConstruction-notify-button {
    width: 100%;
    margin: 15px 0;
  }
}
.UnderConstruction-notify-button:hover {
  background-color: #fc0d00;
}
.UnderConstruction-notify-button:focus {
  outline: none;
}

.UnderConstruction-social-networks {
  width: 100%;
  position: fixed;
  text-align: center;
  bottom: 30px;
}

.UnderConstruction-social-networks-link {
  color: white;
  opacity: .8;
  display: inline-block;
  text-decoration: none;
  transition: all .3s ease-in;
  margin-right: 20px;
}
.UnderConstruction-social-networks-link:hover {
  opacity: 1;
  text-decoration: underline;
}
.UnderConstruction-social-networks-link:last-child {
  margin-right: 0;
}

.UnderConstruction-social-networks-image {
  width: 26px;
}

.UnderConstruction-social-networks-text {
  display: block;
  margin-top: 3px;
  font-size: 12px;
}
